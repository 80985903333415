import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap} from 'rxjs';
import { Image } from "app/models/Image";
import { environment } from 'environments/environment';


const routes = {
  image: () => `api/images`,
  imageWithId: (id: string) => `api/images/${id}`,
  upload: () => `api/images/upload`,
  uploadInFolder: (folderName: string) => `api/images/upload/${folderName}`,
  getByCategory: (category: string) => `api/images/category/${category}`


};
@Injectable({
  providedIn: 'root'
})
export class ImageService {
  serveur_name = environment.serveur_name;
  host_name = environment.host_name;
  private imagesUrl = this.host_name + "/";
constructor(private httpClient: HttpClient) { }

getAllImages(): Observable<Image[]> {
  return this.httpClient.get<Image[]>(this.imagesUrl + routes.image());
}


getImage(id: string): Observable<Image> {
  return this.httpClient.get<Image>(this.imagesUrl + routes.imageWithId(id));
}
getImageByCategory(category: string): Observable<Image> {
  return this.httpClient.get<Image>(this.imagesUrl + routes.getByCategory(category));
}
deleteImage(id: string): Observable<Image> {
  return this.httpClient.delete<Image>(this.imagesUrl + routes.imageWithId(id));
}

uploadImage(image: any): Observable<any> {
  const formdata: FormData = new FormData();

  formdata.append('file', image);
  return this.httpClient.post<any>(this.imagesUrl + routes.upload(), formdata);
}
uploadImageInFolder(image: any, folder: string): Observable<any> {
  const formdata: FormData = new FormData();
  formdata.append('file', image);
  return this.httpClient.post<any>(this.imagesUrl + routes.uploadInFolder(folder), formdata).pipe(
    tap(response => console.log('Upload Response:', response))
  );
}

}
